<template>
  <div id="app">
    <div class="top-toolbar">
      <v-btn class="top-toolbar__history left-button" color="#9baba6" small icon @click="$router.go(-1)">
        <v-icon class="icon" size="28px">mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn class="top-toolbar__history right-button" color="#9baba6"  small icon @click="$router.go(+1)">
        <v-icon class="icon" size="28px">mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <div class="main-toolbar">
      <v-icon 
      size="36px"
      class="hamburger-menu-closed ml-4"
      @click="openNav"
      v-if="!menuOpen"
      >mdi-menu</v-icon>

      <v-spacer></v-spacer>

      
      <div class="search-box">
        <input class="search-box__input" type="text" id="main-toolbar__search-box" placeholder="      Search" autocomplete="off" value=""
        @focus="removePlaceholders"
        @blur="resetSearchbox">
        <v-icon 
        v-if="!searchboxActive"
        class="search-box__icon"
        @click="removePlaceholders"
        >mdi-magnify</v-icon>
      </div>
      

      <v-avatar size="40" class="profile-img mr-6 auto">
        <v-img src="@/assets/images/my-pic.jpg"></v-img>
      </v-avatar>

    </div>

    <div class="ui-container"> 
     
     <div class="left-view">
      
       <div class="main-navigation__container">
         <v-icon class="close-nav-icon"
         @click="closeNav"
         v-if="menuOpen"
         >mdi-close</v-icon>
        
       <nav class="main-navigation">
         <img src="@/assets/logo.svg" alt="sidekick logo"
         class="logo">
        <ul>
           <router-link to="/"
           class="main-navigation__link">
           <img src="@/assets/images/navigation/home.svg"/>
           <div class="main-navigation__link-title">Home</div>
           </router-link>

           <router-link to="/"
           class="main-navigation__link">
           <img src="@/assets/images/navigation/inbox.svg"/>
           <div class="main-navigation__link-title">Inbox</div>
           </router-link>
        
           <router-link to="/"
           class="main-navigation__link">
           <img src="@/assets/images/navigation/tasks.svg"/>
           <div class="main-navigation__link-title">Tasks</div>
           </router-link>

           <router-link to="/"
           class="main-navigation__link">
           <img src="@/assets/images/navigation/time_and_expense.svg"/>
           <div class="main-navigation__link-title">T&E</div>
           </router-link>

           <router-link to="/"
           class="main-navigation__link">
           <img src="@/assets/images/navigation/resources.svg"/>
           <div class="main-navigation__link-title">Resources</div>
           </router-link>

           <hr/>
        
           <router-link to="/"
           class="main-navigation__link">
           <img src="@/assets/images/navigation/notes.svg"/>
           <div class="main-navigation__link-title">Notes</div>
           </router-link>
        
           <router-link to="/"
           class="main-navigation__link">
           <img src="@/assets/images/navigation/files.svg"/>
           <div class="main-navigation__link-title">Files</div>
           </router-link>
        

        </ul>
       </nav>
      </div>
      <div class="left-content">
        <!-- <h1>This is where the user will be able to view their tasks etc.</h1>
        <br/>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eu arcu nibh. Curabitur </p>
        <br/> -->

        <v-list class="task-list">
      <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="task-list__job-list" v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-card
          v-for="child in item.items"
          :key="child.title"
          class="task-list__card"
        >
          <v-icon 
          class="task-list__checkbox"
          >
          mdi-check-circle-outline
          </v-icon>

          <div class="task-list__profile-pic">
            <v-avatar size="63" class="profile-img auto">
            <v-img src="@/assets/images/dehsen.png"></v-img>
           </v-avatar>
          </div>

          <div class="task-list__info">
            <v-list-item-content>
              <p class="task-list__user-name">Dehsen Ragavulu</p>
              <v-list-item-title class="task-list__task-name" v-text="child.title"></v-list-item-title>
              <time class="task-list__due-date">Due: Feb 19</time>
            </v-list-item-content>
          </div>
<!--           
          <div class="task-list__info">
            <v-list-item-content>
              <li>Dehsen Ragavulu</li>
              <v-list-item-title v-text="child.title"></v-list-item-title>
              <li>Due: Feb 19</li>
            </v-list-item-content>
          </div> -->

          <div class="task-list__icons">
            <v-btn
        class="task-icon"
        small
      >
        
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 34 34"><path d="M0,0H34V34H0Z" fill="none"/><g transform="translate(3 1)"><rect width="7" height="2" transform="translate(7.636 21.778)" fill="#ff595a"/><rect width="20" height="2" transform="translate(4 15.556)" fill="#ff595a"/><rect width="20" height="2" transform="translate(4 9.333)" fill="#ff595a"/><path d="M27.889,4h-6.5a4.707,4.707,0,0,0-8.773,0h-6.5a2.822,2.822,0,0,0-.622.06,3.158,3.158,0,0,0-1.571.825,3,3,0,0,0-.669.96A2.8,2.8,0,0,0,3,7V28a2.983,2.983,0,0,0,.249,1.17,3.109,3.109,0,0,0,2.24,1.785A4.183,4.183,0,0,0,6.111,31H27.889A3.066,3.066,0,0,0,31,28V7A3.066,3.066,0,0,0,27.889,4ZM17,3.625A1.155,1.155,0,0,1,18.167,4.75a1.167,1.167,0,0,1-2.333,0A1.155,1.155,0,0,1,17,3.625ZM27.889,28H6.111V7H27.889Z" transform="translate(-3 -1)" fill="#8bd3bd"/></g></svg>
        
      </v-btn>
            <v-btn
        class="task-icon"
        small
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 34 34"><path d="M16.152,2A14.167,14.167,0,1,0,30.333,16.167,14.16,14.16,0,0,0,16.152,2Zm.014,25.5A11.333,11.333,0,1,1,27.5,16.167,11.33,11.33,0,0,1,16.167,27.5Z" transform="translate(0.833 0.833)" fill="#8bd3bd"/><path d="M0,0H34V34H0Z" fill="none"/><path d="M13.125,7H11v8.5l7.437,4.463L19.5,18.22l-6.375-3.783Z" transform="translate(4.583 2.917)" fill="#ff595a"/></svg>
      </v-btn>
            <v-btn
        class="task-icon"
        small
      >
        <v-icon dark>
          mdi-dots-horizontal
        </v-icon>
      </v-btn>
          </div>

            
        </v-card>

      </v-list-group>
    </v-list>
      </div>
       
     
      
     </div>
      
     <div class="right-view">
       <div class="right-content">
         <div class="task-view">
           <div class="task-view__toolbar">
           <div class="task-view__toolbar-icons">

            

      <v-btn
        class="task-icon"
        small
      >
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21" height="30" viewBox="0 0 28 30"><defs><linearGradient id="a" x1="0.063" y1="0.031" x2="0.968" y2="0.963" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#8bd3bd"/><stop offset="0.512" stop-color="#8bd3bd"/><stop offset="1" stop-color="#ff595a"/></linearGradient></defs><g transform="translate(0)"><rect width="7" height="2" transform="translate(7.636 21.777)" fill="#ff595a"/><rect width="20" height="2" transform="translate(4 15.556)" fill="#ff595a"/><rect width="20" height="2" transform="translate(4 9.333)" fill="#ff595a"/><path d="M27.889,4h-6.5a4.707,4.707,0,0,0-8.773,0h-6.5a2.822,2.822,0,0,0-.622.06,3.158,3.158,0,0,0-1.571.825,3,3,0,0,0-.669.96A2.8,2.8,0,0,0,3,7V28a2.983,2.983,0,0,0,.249,1.17,3.109,3.109,0,0,0,2.24,1.785A4.183,4.183,0,0,0,6.111,31H27.889A3.066,3.066,0,0,0,31,28V7A3.066,3.066,0,0,0,27.889,4ZM17,3.625A1.155,1.155,0,0,1,18.167,4.75a1.167,1.167,0,0,1-2.333,0A1.155,1.155,0,0,1,17,3.625ZM27.889,28H6.111V7H27.889Z" transform="translate(-3 -1)" fill="url(#a)"/></g></svg>
        
      </v-btn>


            <v-btn
        class="task-icon"
        small
      >
        <svg id="attachment-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="11" viewBox="0 0 24 11"><defs><linearGradient id="a" x1="0.892" y1="0.141" x2="0.123" y2="0.862" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#ff595a"/><stop offset="1" stop-color="#8bd3bd"/></linearGradient></defs><path d="M2,12.5C2,9.46,4.952,7,8.6,7H21.2C23.852,7,26,8.79,26,11s-2.148,4-4.8,4H11a2.785,2.785,0,0,1-3-2.5A2.785,2.785,0,0,1,11,10h9v2H10.892a.5.5,0,0,0,0,1H21.2a2.234,2.234,0,0,0,2.4-2,2.234,2.234,0,0,0-2.4-2H8.6c-2.316,0-4.2,1.57-4.2,3.5S6.284,16,8.6,16H20v2H8.6C4.952,18,2,15.54,2,12.5Z" transform="translate(-2 -7)" fill="url(#a)"/></svg>
      </v-btn>
      
      <v-btn
        class="task-icon"
        small
      >
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28"><defs><linearGradient id="a" x1="0.06" y1="0.067" x2="1" y2="0.955" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#8bd3bd"/><stop offset="0.512" stop-color="#8bd3bd"/><stop offset="1" stop-color="#ff595a"/></linearGradient></defs><g transform="translate(-1169 -123)"><g transform="translate(1178.167 136)"><path d="M6.007,2A4.059,4.059,0,0,0,2,6.108a4.059,4.059,0,0,0,4.007,4.108,4.063,4.063,0,0,0,4.015-4.108A4.063,4.063,0,0,0,6.007,2Zm0,7.395A3.248,3.248,0,0,1,2.8,6.108,3.248,3.248,0,0,1,6.011,2.822,3.248,3.248,0,0,1,9.22,6.108,3.248,3.248,0,0,1,6.011,9.395Z" transform="translate(-1.178 -1.178)" fill="#8bd3bd"/><path d="M0,0H9.666V9.86H0Z" fill="none"/><path d="M11.616,7H11V9.465l2.157,1.294.308-.505-1.849-1.1Z" transform="translate(-6.599 -4.124)" fill="#ff595a"/></g><g transform="translate(1169 123)"><path d="M0,0H28V28H0Z" fill="none"/><g transform="translate(3 2)"><path d="M7.889,12.8h0v0ZM25,6.8V23.6A2.43,2.43,0,0,1,22.556,26H5.444A2.422,2.422,0,0,1,3,23.6L3.012,6.8A2.411,2.411,0,0,1,5.444,4.4H6.667V2H9.111V4.4h9.778V2h2.444V4.4h1.222A2.43,2.43,0,0,1,25,6.8ZM5.444,9.2H22.556V6.8H5.444ZM22.556,23.6v-12H5.444v12Zm-4.889-8.4V12.8h0Zm-2.444-2.4v0h0Z" transform="translate(-3 -2)" fill="url(#a)"/></g></g></g></svg>
      </v-btn>

      <v-btn
        class="task-icon task-chat-btn"
        small
      >
        
          <svg id="task-chat-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"><defs><linearGradient id="a" x1="0.04" y1="0.057" x2="1" y2="0.847" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#8bd3bd"/><stop offset="0.377" stop-color="#8bd3bd"/><stop offset="1" stop-color="#ff595a"/></linearGradient></defs><path d="M23.6,2H4.4A2.407,2.407,0,0,0,2,4.4V26l4.8-4.8H23.6A2.407,2.407,0,0,0,26,18.8V4.4A2.407,2.407,0,0,0,23.6,2Zm0,16.8H6.8L4.4,21.2V4.4H23.6Z" transform="translate(-2 -2)" fill="url(#a)"/></svg>
        
      </v-btn>

      <v-btn
        class="task-icon more-options"
        small
      >
        
          <v-icon dark>
          mdi-dots-horizontal
        </v-icon>
        
      </v-btn>


      
          </div>
          <h2 class="task-view__heading">User on boarding process</h2>

         </div>

         <div class="task-view__body">

           <v-card
          class="colleague-chatbox"
        >
          

          <div class="colleague-chatbox__img">
            <v-avatar size="63" class="profile-img auto">
            <v-img src="@/assets/images/dehsen.png"></v-img>
           </v-avatar>
          </div>

          <div class="colleague-chatbox__info">
            <v-list-item-content>
              <p class="colleague-chatbox__user-name">Dehsen Ragavulu</p>
              <p class="colleague-chatbox__message">Hi <span class="colleague-chatbox__direct-message">Masnoena Ambarker</span>, yes I've completed the task. I will update the artwork in a few mins and let you know. 
<span class="colleague-chatbox__direct-message">Jenita Ferreira</span>
</p>          
            </v-list-item-content>
          </div>          
            
        </v-card>

           <v-card
          
          class="user-chatbox"
        >
        
          <div class="user-chatbox__info">
            <v-list-item-content>
              <p class="user-chatbox__message">Hi <span class="user-chatbox__direct-message">Masnoena Ambarker</span>, yes I've completed the task. I will update the artwork in a few mins and let you know. 
<span class="user-chatbox__direct-message">Jenita Ferreira</span>
</p>

              
            </v-list-item-content>
          </div>          
            
        </v-card>

        <v-card
          class="colleague-chatbox"
        >
          

          <div class="colleague-chatbox__img">
            <v-avatar size="63" class="profile-img auto">
            <v-img src="@/assets/images/dehsen.png"></v-img>
           </v-avatar>
          </div>

          <div class="colleague-chatbox__info">
            <v-list-item-content>
              <p class="colleague-chatbox__user-name">Dehsen Ragavulu</p>
              <p class="colleague-chatbox__message">Hi <span class="colleague-chatbox__direct-message">Masnoena Ambarker</span>, yes I've completed the task. I will update the artwork in a few mins and let you know. 
<span class="colleague-chatbox__direct-message">Jenita Ferreira</span>
</p>          
            </v-list-item-content>
          </div>          
            
        </v-card>

        <v-card
          
          class="user-chatbox"
        >
        
          <div class="user-chatbox__info">
            <v-list-item-content>
              <p class="user-chatbox__message">Hi <span class="user-chatbox__direct-message">Masnoena Ambarker</span>, yes I've completed the task. I will update the artwork in a few mins and let you know. 
<span class="user-chatbox__direct-message">Jenita Ferreira</span>
</p>

              
            </v-list-item-content>
          </div>          
            
        </v-card>

        <v-card
          
          class="user-chatbox"
        >
        
          <div class="user-chatbox__info">
            <v-list-item-content>
              <p class="user-chatbox__message">Hi <span class="user-chatbox__direct-message">Masnoena Ambarker</span>, yes I've completed the task. I will update the artwork in a few mins and let you know. 
<span class="user-chatbox__direct-message">Jenita Ferreira</span>
</p>

              
            </v-list-item-content>
          </div>          
            
        </v-card>

        <v-card
          class="colleague-chatbox"
        >
          

          <div class="colleague-chatbox__img">
            <v-avatar size="63" class="profile-img auto">
            <v-img src="@/assets/images/dehsen.png"></v-img>
           </v-avatar>
          </div>

          <div class="colleague-chatbox__info">
            <v-list-item-content>
              <p class="colleague-chatbox__user-name">Dehsen Ragavulu</p>
              <p class="colleague-chatbox__message">Hi <span class="colleague-chatbox__direct-message">Masnoena Ambarker</span>, yes I've completed the task. I will update the artwork in a few mins and let you know. 
<span class="colleague-chatbox__direct-message">Jenita Ferreira</span>
</p>          
            </v-list-item-content>
          </div>          
            
        </v-card>

          
         </div>

         <div class="task-chat">

           <div class="task-chat__attachment">
              <v-btn
        class="task-icon"
        small fab
      >
        <v-icon class="attachment-icon" dark>
          mdi-attachment
        </v-icon>
      </v-btn>

           </div>

              
           <div class="task-chat__message-input-container">
             <input class="task-chat__add-message" type="text">
           </div>

            
           <div class="task-chat__record-or-send">
            <v-btn
        class="task-icon"
        small fab
        
      >
        <v-icon dark>
          mdi-microphone
        </v-icon>
      </v-btn>
           </div>



         </div>

         
         </div>
         

    
 
       </div>
      
     </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      searchboxActive: false,
      items: [
        {
          action: 'mdi-ticket',
          items: [{ title: 'List Item' }],
          title: '99c Racebook - Website - Digital',
        },
        {
          action: 'mdi-silverware-fork-knife',
          active: true,
          items: [
            { title: 'Increase page load speed' },
            { title: 'User on boarding process' },
            { title: 'Create single AXIOS API service' },
            { title: 'Reporting tools specifications' },
            { title: 'API Integration Tests'},
          ],
          title: 'Shoprite Price Aggregator App',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'List Item' }],
          title: 'Boost Time Test',
        },
        
      ],
    };
  },
  methods: {
    openNav() {
      this.menuOpen = true;
      // const closeNav = document.querySelector('.close-nav-icon');
      // const hamburgerIcon = document.querySelector('.hamburger-menu-closed');
      const navigationBar = document.querySelector('.main-navigation__container');
      navigationBar.classList.add('show-main-navigation__container');
      // hamburgerIcon.style.visibility = 'hidden';
    },
    closeNav() {
      this.menuOpen = false;
      // const hamburgerIcon = document.querySelector('.hamburger-menu-closed');
      const navigationBar = document.querySelector('.main-navigation__container');
      navigationBar.classList.remove('show-main-navigation__container');
      // hamburgerIcon.style.visibility = 'visible';
    },
    removePlaceholders() {
      this.searchboxActive = true;
      const searchbox = document.getElementById('main-toolbar__search-box');
      searchbox.focus();
      searchbox.placeholder="";
    },
    resetSearchbox() {
      this.searchboxActive = false;
      const searchbox = document.getElementById('main-toolbar__search-box');
      searchbox.placeholder="      Search";
    },
    markComplete() {
      // when user clicks checkbox
      // check if it is checked
      // the task should be it's own component
    }
  },

};
</script>

<!-- Use preprocessors via the lang attribute! e.g. <style lang="scss"> -->
<style lang="scss">
@import '@/scss/_variables.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5% !important;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  // text-align: center;
  color: #2c3e50;
}

ul li {
  list-style-type: none;
}

 .ui-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 85px);
  // height: calc(100vh);
  background-color: #F8F9F9;
  border-top: 1px solid #2c3e50;
 }
 
 .left-view, .right-view {
  width: 50%;
  height: 100%;
 }
 
 .left-view {
  display: flex;
  flex: 0 0 auto;
  height: calc(99.5vh - 85px);
  background-color: transparent;
  
 }

 .right-view {
       border-left: 1px solid rgb(44 62 80 / 35%);
 }



 
 /********************************************************************* - NAVIGATION - */

 /*** TOP BAR ***/
.top-toolbar {
  // min-height: 40px !important;
  height: 4rem !important;
  background-color: $black-2;

  & .left-button {
    margin-right: 2rem;
    margin-left: 1rem
  }

  &__history {
    margin: auto;
    margin-top: 3px;

    &::before {
      margin-top: 6px;
    }

    & .icon {
      margin-top: 8px;
    }
    // padding-bottom: 8px;
    // margin-top: 7px;
  }
}

 /*** MAIN TOOLBAR ***/
 .main-toolbar {
   display: flex;
   height: 4.5rem;
   width: 100%;
   text-align: left;

   & .search-box {
     position: relative;
     margin: auto;
     margin-right: 2rem;
     border: 1px solid grey;
     border-radius: 100px;

     &__input {
       font-size: 1.6rem;
       padding: 0.4rem;
     outline: none;
     
     }

     &__icon {
       font-size: 1.9rem !important;
       position: absolute !important;
       top: 5px !important;
       left: 6px !important;

       &:hover {
         cursor: text !important;
       }
     }
   }

   & .profile-img {
     
     margin: auto;
     margin-right: 1rem;
   }
}

.hamburger-menu-closed {
  font-size: 3.6rem;
  
  margin: auto;
  margin-left: 0.6rem;
  &:hover {
    cursor: pointer;
  }
}

 .main-navigation__container {
transform: translateX(-233px);
  flex: 0;
  // flex: 0 0 auto;
  position: relative;
  z-index: 10;
  padding-top: 0.5rem;
  background-color: black;
  height: 100%;
  width: 23.3rem;
  width: 3.3rem;
  color: white;
  transition: transform .5s ease;

  & .logo {
    margin-left: 5px;
    margin-top: 4px;
    margin-bottom: 25px;
  }
  }
  
 .show-main-navigation__container {
  width: 23.3rem;
  transform: translateX(0);
  flex: 0 0 auto;
  }


 
 .main-navigation__container ul li {
  list-style-type: none;
  text-align: left;
  margin-bottom: 1rem;
 }
 
 .close-nav-icon {
  position: absolute !important;
  font-size: 3.3rem !important;
  top: 9px;
  left: 194px;
  width: 3rem;
  height: 3rem;
  color: white !important;
  
  &:hover {

  }
 }

 .main-navigation {

   &__link {
    display: flex;
    gap: 12px;
    padding: 1rem 0 1rem 0.6rem;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 2rem;
    transition: all .05s ease;

    &:hover {
        background-color: rgba($grey-1, 0.2);
        
    }
    &:active {
      
      background-color: $green-1;

    }
   }

 }
 
 .left-content, .right-content {
   height: calc(99.5vh - 85px);
   max-height: calc(99.5vh - 85px);
    overflow-x: scroll;
 }
 .left-content {
   width: 95%;
   padding: 0 1rem;
 }
 .right-content {
  // padding-left: 0.5rem;
 }

  /********************************************************************* - TASKS - */
   /* - TASK LIST- */
  .task-list {
    background-color: #f8f9f9 !important;

    & .v-list-group {
          border-bottom: 1px solid grey;
    }

    &__job-list {
      font-size: 1.6rem !important;
      margin-bottom: 0.3rem;
    }
    &__task-name {
      margin-top: 0.1rem !important;
      margin-bottom: 0.3rem !important;
    }
    &__user-name, &__due-date {
      font-size: 1.2rem !important;
    }
    &__user-name {
      margin-bottom: 0 !important
    }

    &__card {
      position: relative;
      display: flex !important;
      align-items: center;
      border: 1px solid rgba(95, 94, 94, 0.242) !important;
      height: 11.2rem;
      margin-bottom: 1.4rem;
      padding: 0.5rem;
      border-radius: 1rem !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.1) !important;
      transition: all 1.05s ease;
      

      & .task-list__icons {
        // margin-left: auto;
        visibility: hidden;
        position: absolute;
        right: 10px;
        top: 6px;

      }
      & .task-icon {
        margin-left: .5rem;
        // box-shadow: $shadow-1;
        
      }

      &:hover {
        // cursor: pointer;
        border: 1px solid rgba(95, 94, 94, 0.442) !important;
        box-shadow: 2px 8px 12px rgba(0,0,0,0.1) !important;
        // transform: scale(1.0005);
      }

      &:hover .task-list__icons {
        visibility: visible;
      }

      & .v-list-item__title {
        font-size: 2rem;
      }
    }

    &__checkbox {
      color: rgba($black-1,0.35) !important;
      position: absolute !important;
      top: 5px;
      left: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    &__profile-pic {
      margin-left: 2.2rem;
    }

    &__info {
      margin-left: 1.8rem;
      margin-top: 0.1rem;
      

    }
  }

  /* - TASK VIEW- */
   .task-view {

     position: relative;

     &__toolbar {
    position: relative;
    margin-bottom: 5.9rem;
    background-color: $white;
    // height: 8.05rem;
    // border-bottom: 1px solid $grey-1 !important;

    #attachment-icon {
      transform: rotate(90deg);
    }

    
  }

    &__toolbar-icons {
      margin-top: 0.6rem;
      padding-left: 1.5rem;

      .task-icon {
        background-color: $white !important;
        margin-right: 0.7rem;
        padding: 1.75rem 0rem !important;
        border: 1px solid transparent;
        // 
        &:hover {
          border: 2px solid $green-1;
          opacity: 1;
        }

        &::before {
          background-color: $grey-1 !important;
        }
      }

      .more-options {
        position: absolute;
        top: 1px;
        right: 5px;
      }
      /***FOR PRESENTATIONAL PURPOSES*** */
   .task-chat-btn {
     border: 2px solid $green-1;
   }
      
    }
    &__heading {
      font-size: 2rem;
      text-align: center;
    }
    

    /***Task View Body ****/
    .task-view__body {
      padding-bottom: 10rem;
    }

    & .colleague-chatbox, .user-chatbox {
      color: $blue-1 !important;
      width: 36rem;
      max-width: 36rem;
    }

    & .colleague-chatbox__message, 
    & .user-chatbox__message {
        font-size: 1.8rem;
        padding-top: 1rem;
        line-height: 1.2;
    }

    & .colleague-chatbox {
      position: relative;
      margin-left: 6rem;
      margin-top: 5rem;
    box-shadow: 6px 8px 12px rgb(147 167 190 / 16%) !important;

      &__img {
        border-radius: 100px !important;
        box-shadow: 6px 8px 12px rgb(147 167 190 / 16%) !important;
        position: absolute;
        top: -35px;
        left: -40px;
      }

      & .v-list-item__content {
          // padding-left: 3rem !important;
          // padding-top: 0.5rem !important;
          padding: 0.5rem 1.3rem 1.2rem 3rem !important;
      }

      &__user-name {
        font-size: 1.2rem;
        text-decoration: underline;
        text-decoration-color: $green-1;
        text-decoration-thickness: 3px;
      }

      &__message {
        
      }

      &__direct-message {
        background-color: $green-1;
      }
    }

    & .user-chatbox {
      background-color: $green-2 !important;
      margin-left: auto;
      margin-top: 3rem;
      margin-right: 6rem;
      box-shadow: -6px 6px 9px rgb(147 167 190 / 16%) !important;

      &__direct-message {
        background-color: $white;
      }

      & .v-list-item__content {
          // padding-left: 3rem !important;
          // padding-top: 0.5rem !important;
          padding: 0.5rem 1.3rem 1.2rem 1.8rem !important;
      }
    }
   
    & .task-chat {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: calc(50% - 2px);
      height: 104px;
      background-color: $green-3;
      position: fixed;
      top: calc(100vh - 95px);
      // left: 100px;
      // 
      & .task-chat__attachment, 
      & .task-chat__record-or-send {
        padding: 0px 2rem;
      }
      &__message-input-container {
      flex: 2 1 auto;
      // flex-grow: auto;
      // height: 40px;
      }

      & .task-icon {
        color: $grey-1;
        
      }

      &__add-message {
        width: 100%;
        // width: 558px !important;
        // max-width: 482px !important;
        font-size: 1.6rem;
        border: 1px solid rgba($grey-1, 0.5);
        background-color: rgb(255 255 255 / 58%);
      border-radius: 100px;
      height: 55px;
      padding: 0.5rem;
      // width: 100%;
      // 
      &:focus {
        border: 1px solid rgba($blue-1, 0.9);
        outline: none;
      }
      }

      & .attachment-icon {
        
        &::before {
          transform: rotate(
-70deg);
        }
      }


    }

    
   }

   
   
  

  /********************************************************************* - JAVASCRIPT CLASSES - */

  .hide {
    visibility: hidden !important;
  }

</style>